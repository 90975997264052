<template>
    <div>
        <h3 class="t-title">
            {{ $t('router.locationHistoryChart') }}
        </h3>

        <location-history-chart-view full-height />
    </div>
</template>

<script>
import LocationHistoryChartView from './LocationHistoryChartView'

export default {
    name: 'DashboardLocationHistoryView',
    components: {
        LocationHistoryChartView,
    },
}
</script>

<style lang="scss" scoped>
.t-title {
    margin: 1rem 1rem 0;
}
</style>
